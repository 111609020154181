.Instruction {
    order: 0;
    animation: halfFadeIn 0.5s;
    background-color: #19232e;
    border-radius: .7vw;
    text-align: left;
    padding: 4%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5vh;
    width: 92%;
}

.OtpForm {
    animation: halfFadeIn 0.5s;
    gap: 2vh;
    display: flex;
    width: 33vw;
    flex-grow: 1;
    max-block-size: 3vw;
    order: 1;
}

.Input::placeholder {
    font-size: .8vw;
}

.Input:hover {
    background-color: #151d26;
}

span {
    font-size: .8vw;
}

.OtpFormWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: .6vw;
    z-index: 1;
}

.Error {
    font-size: 1vw;
    color: rgb(255, 0, 72);
    display: flex;
    margin-left: .8vw;
    position: absolute;
}

.InputContainer {
    width: 100%;
    order: 2;
}

.otpInput{
    background-color: #19232e;
    font-size: 1vw;
    padding-top: 2.5vh;
    padding-bottom: 2.3vh;
    padding-left: 1vw;
    padding-right: 1vw;
    border-radius: .7vw;
    width: 2vw;
}

.Input {
    width: 89%;
    border-radius: .7vw;
    font-size: .9vw;
    background-color: #19232e;
    border: none !important;
    color: white;
    padding-left: 1vw;
    padding-right: 1vw;
    height: 100%;
    transition: background-color .5s;
}

.CloseButton,
.CloseButtonModal {
    color: rgb(148, 147, 147);
    background-color: #17212B;
    padding: 0;
    order: 1;
    height: 3vw;
    width: 3vw;
    min-width: 3vw;
}

.CloseButtonIcon {
    width: 50%;
    height: 50%;
}

.CloseButton:hover,
.CloseButtonModal:hover {
    background-color: #151d26;
}

.OperatingSystems {
    display: inline-flex;
    gap: 2vw;
}

.IosIcon,
.GoogleIcon,
.WindowsIcon,
.MacosIcon {
    transition: fill .5s;
    align-items: center;
}

.IosIcon path,
.GoogleIcon path,
.WindowsIcon path,
.MacosIcon path{
    /* filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.1)); */
    transition: fill .2s, filter .5s ease;
    fill: #637d97;
}

.IosTitle,
.GoogleTitle,
.WindowsTitle,
.MacosTitle {
    color: #637d97;
    font-weight: 700;
    transition: color .5s;
}

.IosIconContainer:hover .IosIcon path {
    fill: #fff;
    /* filter: drop-shadow(0 0 2px rgba(255, 255, 255, 1)); */
}

.IosIconContainer:hover .IosIcon {
    fill: #fff;
}

.IosIconContainer:hover .IosTitle {
    color: #fff;
    /* filter: drop-shadow(0 0 2px rgba(255, 255, 255, 1)); */
}

.MacosIconContainer:hover .MacosIcon path {
    fill: #fff;
    /* filter: drop-shadow(0 0 2px rgba(255, 255, 255, 1)); */
}

.MacosIconContainer:hover .MacosIcon {
    fill: #fff;
}

.MacosIconContainer:hover .MacosTitle {
    color: #fff;
    /* filter: drop-shadow(0 0 2px rgba(255, 255, 255, 1)); */
}

.GoogleIconContainer:hover .GoogleIcon path {
    fill: #fff;
    /* filter: drop-shadow(0 0 2px #3ddc84); */
}

.GoogleIconContainer:hover .GoogleIcon {
    fill: #3ddc84;
}

.GoogleIconContainer:hover .GoogleTitle {
    color: #3ddc84;
    /* filter: drop-shadow(0 0 2px #3ddc84); */
}

.WindowsIconContainer:hover .WindowsIcon path {
    fill: #fff;
    /* filter: drop-shadow(0 0 2px #00A4EF); */
}

.WindowsIconContainer:hover .WindowsIcon {
    fill: #00A4EF;
}

.WindowsIconContainer:hover .WindowsTitle {
    color: #00A4EF;
    /* filter: drop-shadow(0 0 2px #00A4EF); */
}

.IosIconContainer,
.GoogleIconContainer,
.WindowsIconContainer,
.MacosIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    cursor: pointer;
    align-items: center;
}

.CodeContainer {
    display: inline-flex;
    gap: 1vw;
    width: 100%;
    height: 3vw;
}

.CodeInput {
    width: 90%;
    border-radius: .7vw;
    font-size: 1vw;
    background-color: #151d26;
    border: none !important;
    color: white;
    padding-left: 1vw;
    padding-right: 1vw;
    transition: background-color .5s;
}

.CopyCodeButton {
    background-color: #151d26;
    min-height: 100%;
    align-content: center;
    border-radius: .7vw;
    padding-left: 1vw;
    padding-right: 1vw;
    font-weight: 700;
    text-align: center;
    transition: background-color .2s;
    cursor: pointer;
    white-space: nowrap;
    font-size: .8vw;
}

.CopyCodeButton:active{
    background-color: #0d1218;
    transform: scale(0.99);
}

.Offer{
    order: 5;
    width: 33vw;
    text-align: left;
    color: #686d71;
    margin-top: .3vw;
}

.Link{
    color: #686d71;
    transition: color .7s;
}

.OfferLink{
    color: #fff;
}

.OfferText{
    font-size: .8vw;
    margin-left: .5vw;
    transition: color .7s;
}

.CopyCodeButtonActive{
    background-color: #0d1218;
    animation: changeColor 0.5s;
}

@keyframes changeColor {
    0% {
        background-color: #151d26;
    }
    50% {
        background-color: #0d1218;
    }
    100% {
        background-color: #151d26;
    }
  }

@media (max-width: 768px) {

    span{
        font-size: 16px;
    }

    .Instruction{
        order: 0;
        margin-top: 10px;
        width: 100%;
        padding-top: 6%;
        padding-left: 6%;
        border-radius: 1.5vh;
        padding-bottom: 6%;
        padding-right: 6%;
        box-sizing: border-box;
    }

    .OtpFormWrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 14px;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
    }

    .OtpForm {
        order: 1;
        width: 100%;
        flex-direction: column;
        row-gap: 14px;
        flex-grow: 0;
        min-height: fit-content;
    }

    .Input,
    .CodeInput {
        border-radius: 1.5vh;
        font-size: 16px;
        padding-left: 5vw;
        padding-right: 4vw;
        padding-top: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
        width: 100%;
        height: 55px;
    }

    .CodeInput{
        width: 90%;
        margin-right: 10px;
    }
    
    .CopyCodeButton{
        font-size: 16px;
        border-radius: 1.5vh;
        text-align: center;
        padding: 0px;
        padding-left: 15px;
        padding-right: 15px;
        min-height: 100%;
        align-content: center;
    }

    .OperatingSystems {
        display: inline-flex;
        gap: 20px;
    }
    
    .Input::placeholder {
        font-size: 16px;
        padding-right: 1vh;
    }

    .CloseButton {
        order: 5;
        border-radius: 1.5vh;
        flex-grow: 0;
        height: 55px;
        max-width: 100%;
    }

    .BlueButton{
        min-height: 55px;
    }

    .CodeContainer{
        height: 55px;
    }

    .IosTitle,
    .GoogleTitle,
    .WindowsTitle,
    .MacosTitle {
        font-weight: 900;
        font-size: 14px;
    }

    .Offer{
        width: 100%;
    }

    .OfferText{
        font-size: 14px;
        width: 100%;
    }
}