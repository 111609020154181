.modal-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    justify-items: center;
    top: 0;
    align-content: center;
    align-items: center;
    background-color: #0A0F15;
}

.modal-content {
    animation: halfFadeIn 0.5s;
    background-color: #19232e;
    margin-top: 0vh;
    border-radius: 1vw;
    border-bottom-right-radius: .4vw;
    border-top-right-radius: .4vw;
    text-align: left;
    padding: 4%;
    padding-top: 1%;
    max-width: 50%;
    max-height: 60%;
    overflow-y: scroll;
    align-self: center;

}

.CloseButtonModal{
    width: 58%;
    margin-top: 1%;
}


.modal-content::-webkit-scrollbar {
    /* border-radius: .7vw; */
    
}

/* Цвет полосы прокрутки */
.modal-content::-webkit-scrollbar-thumb {
    background-color: #10161d; /* цвет полосы */
    border-radius: 5vw;
}

/* Цвет фона полосы прокрутки */
.modal-content::-webkit-scrollbar-track {
    background-color: #151d26; /* цвет фона */
    border-radius: 5vw;
}

/* Определяет ширину полосы прокрутки */
.modal-content::-webkit-scrollbar {
    width: 15px;
    border-radius: 5vw;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
}


@media (max-width: 768px) {
    .modal-content{
        max-width: 82%;
        max-height: 80%;
        border-radius: 1.5vh;
        justify-content: center;
        justify-items: center;
        justify-self: center;
        margin-left: 4%;
    }

    .CloseButtonModal{
        width: 90%;
        height: 55px;
        margin-top: 10px;
        border-radius: 1.5vh;
        font-size: 16px;
        margin-left: 4%;
    }

    .modal-content::-webkit-scrollbar-thumb {
        border-radius: 3vh;
        border-top-left-radius: 0%;
        border-bottom-left-radius: 0%;
    }
    
    /* Цвет фона полосы прокрутки */
    .modal-content::-webkit-scrollbar-track {
        background-color: #151d26; /* цвет фона */
        border-radius: 50px;
    }
    
    /* Определяет ширину полосы прокрутки */
    .modal-content::-webkit-scrollbar {
        border-radius: 10px;
    }
}

