@font-face {
  font-family: 'Boldfinger';
  src: local('Boldfinger'), url(./fonts/Boldfinger.woff) format('woff');
}


.App {
  text-align: center;
  height: 100vh !important;
  background: linear-gradient(#0f161f, #06090c);
  align-content: center;
}

.MadeInRussiaIconContainer {
  Width: 140px;
  height: 50px;
}

input:focus {
  outline: none;
}

.checkout-modal__overlay {
  backdrop-filter: blur(6px);
}

.MadeInRussiaIcon {
  transition: fill .5s;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.MadeInRussiaIcon:hover {
  fill: #4b6783;
}

.FooterEmail,
.FooterTelegram,
.Credits {
  color: #1b2733;
  font-weight: 700;
  font-size: .8vw;
  transition: color .5s;
  z-index: 1
}

.FooterEmail:hover,
.FooterTelegram:hover,
.Credits:hover {
  color: #4b6783 !important;
}

.Footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20vw;
  justify-self: center;
  justify-content: space-around;
}

.Credits{
  margin-bottom: 0px;
  position: absolute;
  bottom: 16px;
  left: 42%;
  font-size: .6vw;
}

.App-header {
  margin-top: 15vh;
  animation: halfFadeIn 0.5s;
  display: flex !important;
  flex-wrap: nowrap !important;
  row-gap: 10px !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center;
  color: white;
  height: 75vh;
}

.HeaderText {
  font-size: 4.5vw;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  font-family: Boldfinger, sans-serif;
  z-index: 1;
  /* filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3)); */
}

.backgroundCounter {
  font-size: 400px;
  font-weight: 900;
  position: fixed;
}

Input {
  border: none !important;
}

#confetti{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.WhiteButton,
.BlackButton,
.BlueButton,
.CloseButton,
.TelegramButton,
.CloseButtonModal {
  border-radius: .7vw;
  font-weight: 700;
  transition: background-color .5s;
  cursor: pointer;
  white-space: nowrap;
  width: 17vw;
  font-size: .9vw;
  align-content: center;
  flex-grow: 1;
  border: none;
}

.WhiteButton {
  order: 5;
  color: #0A0F15;
  background-color: white;
}

.BlackButton {
  order: 2;
  color: white;
  background-color: #17212B;
}

.TelegramButton {
  order: 4;
  color: white;
  background-color: #229ED9;
}

.TwoButtons,
.ThreeButtons {
  animation: halfFadeIn 0.5s;
  display: flex;
  justify-content: space-between;
  font-size: 2vh;
  gap: 1vw;
  flex-grow: 1;
  max-block-size: 3vw;
  width: 33vw;
}

.ThreeButtons {}

.WhiteButton:hover {
  background-color: rgb(172, 172, 172);
}

.BlackButton:hover {
  background-color: #121922;
}

.BlueButton:hover {
  background-color: rgb(172, 172, 172);
}

.BlueButton {
  background-color: white;
  width: 17vw;
  padding-left: 0;
  color: #0A0F15;
  padding-right: 0;
  order: 3;
}

.error-popup {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #19232e;
  color: #e2e2e2;
  padding: 20px;
  font-size: .8vw;
  padding-left: 1.4vw;
  padding-right: 1.4vw;
  height: 3vh;
  align-items: center;
  align-content: center;
  border-radius: .7vw;
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.3));
}

@keyframes halfFadeIn {
  from {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide {
  from {
    margin-left: -200%;
  }

  to {
    margin-left: 200%;
  }
}

@media (max-width: 768px) {
  .HeaderText {
    font-size: 11.6vw;
  }

  .FooterEmail,
  .FooterTelegram,
  .Credits {
    color: #1b2733;
    font-weight: 700;
    font-size: 16px;
    transition: color .5s;
  }

  .FooterTelegram{
    order: 1;
  }
  
  .FooterEmail{
    order: 2;
  }

  .Footer{
    flex-direction: column;
  }

  .App-header{
    height: 62%;
  }

  .Credits{
    font-size: 14px;
    right: 4%;
    text-align: right;
  }

  .TwoButtons,
  .ThreeButtons {
    margin-top: 1vh;
    width: 360px;
    max-block-size: 130px;
    height: 260px;
    padding: 0px;
    font-size: 16px;
    row-gap: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 0;
  }

  .ThreeButtons {
    max-block-size: 200px;
    height: 600px;
  }

  .CloseButton {
    width: 100% !important;
    font-size: 16px;
    min-height: 55px;
  }

  .WhiteButton,
  .BlackButton,
  .BlueButton,
  .TelegramButton {
    width: 100%;
    height: 100%;
    border-radius: 1.5vh;
    font-size: 16px;
  }

  .error-popup {
    font-size: 14px;
    border-radius: 1.5vh;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
  }
}