.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%; /* Занять всю высоту родительского элемента */
  }
  
  .loading {
    width: 20px;
    height: 20px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-left-color: #0a1c28;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }